

.banner{
    width: 100%;
    height: 623px;
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
    position: relative;
}
.banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner_text{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 6em;
    color: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
}
.banner_text h1{
    font-family: 'BrandonPrintedTwoShadow', 'Courier New', Courier, monospace;
    font-size: 8em;
    max-width: 800px;
}
.link__card_grid{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    overflow-x: scroll;
}
.link__card_grid::-webkit-scrollbar{
    display: none;
}
.link__card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    cursor: pointer;
}
.link__card_img{
    width: 80px;
    height: 80px;
}
.link__card_img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.link__card_text{
    color: #048eac;
    text-decoration: underline;
}
.link__card a{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
}

@media screen and (max-width: 800px) {
    .link__card{
        margin: 1em;
    }
    .link__card_grid{
        align-items: flex-start;
    }
    .banner{
        height: 300px;
    }
    .banner_text h1{
        font-size: 3em;
    }
    .banner_text{
        padding: 0;
    }
}