.formulario_login{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formulario_items,
.formulario_form{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.formulario_items{
    padding: 2em 3em;
    background-color: #ffffff;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, .3);
}
.formulario_form{
    padding: 1em 0;
}
.formulario_form input{
    border-radius: 1em;
    padding: .5em;
    width: 100%;
    border: none;
    outline: #019EB6 1px solid;
    margin-bottom: 1em;
}
.boton-login{
    background: #019EB6;
    padding: 1em;
    border-radius: 2em;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}