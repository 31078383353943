.reservation-details,
.passengers-list {
  background-color: #f8f9fa;
  border-radius: 10px;
}

.reservation-details h2,
.passengers-list h2 {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #343a40;
}

.reservation-details p,
.passengers-list p {
  font-family: 'Arial', sans-serif;
  color: #495057;
}

.passenger-item {
  border: 1px solid #9fa1a4;
  padding: 1em;
}


.list-group-item p {
  margin: 0;
  padding: 5px 0;
}
