.card__grid{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}
.card__grid > .card__content:nth-child(odd){
    flex-direction: row-reverse;
}
.card__content{
    display: flex;
    margin: 2em 0;
}
.card__img{
    width: 450px;
    min-width: 450px;
}
.card__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card__text{
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: normal;
}
.card__icon{
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card__icon img{
    width: 100px;
    height: 100px;
}
.card__icon p{
    color: #048eac;
    font-size: 2em;
}
.card__button{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.card__button img{
    width: 120px;
}

@media screen and (max-width:750px){
    .card__content{
        flex-direction: column;
        align-items: center;
    }
    .card__img{
        width: 100%;
        height: 300px;
        min-width: 100%;
    }
    .card__grid > .card__content:nth-child(odd){
        flex-direction: column;
    }
}