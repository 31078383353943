.booking__text{
    background-color: #027d9d;
    padding: 1em;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.booking__text p{
    width: 100%;
    max-width: 800px;
}
.booking__form_all{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}
.booking__form_all form{
    display: flex;
    flex-direction: column;
    align-items: end;
}
.boton_to_send{
    background-color: transparent;
    color: #fff;
    width: auto;
    border: none;
    border-radius: 2em;
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.boton_to_send img{
    width: 100px;
}
.send_form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.send_form > img{
    width: 300px;
}
.booking__form_items{
    width: 100%;
    max-width: 800px;
    background: hsla(192, 97%, 31%, 0.166);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
}
.booking__form_items > div{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.booking__form_items > div:last-child{
    width: 95% !important;
}
.booking__form_textarea{
    width: 95% !important;
}
.booking__form_items input,
.booking__form_items select,
.booking__form_items textarea{
    width: 100%;
    border: none;
    padding: .4em;
    margin: 0 0 .5em 0;
    border-top-right-radius: .5em;
    border-bottom-left-radius: .5em;
}
.booking__form_items input:focus,
.booking__form_items textarea:focus{
    outline: 1px solid #027d9d;
}
.booking__form_items label{
    width: 100%;
}
.react-datepicker-wrapper{
    width: 100%;
}
.booking__error{
    color: red;
}
form{
    position: relative;
}
.success-message{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #f8fafe;
    color: #027d9d;
}
@media screen and (max-width: 500px) {
    .booking__form_items > div{
        width: 95% !important;
    }
}