.form__nationality > div{
    width: 100%;
}
.form__send{
    margin: 1em auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form__send button{
    background-color: #027d9d;
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 2em;
    border-radius: 2em;
}