.menu{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu__all{
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 0;
}
.menu__logo{
    width: 250px;
}
.menu__logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.menu__content{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.menu__content li a{
    color: #019EB6;
    display: flex;
    text-align: center;
    padding:10px 12px;
}
.menu__paiment{
    width: 340px;
}
.menu__paiment img{
    width: 100%;
}
@media screen and (max-width: 500px) {
    .menu{
        background-color: #fff;
    }
    .menu__all{
        align-items: start;
        padding: 10px;
    }
    .menu__content{
        height: 0;
        overflow: hidden;
    }
    .menu__all,
    .menu__content{
        flex-direction: column;
        position: relative;
        width: 100%;
    }
    .menu__logo{
        width: 150px;
    }
    .icon_menu_top{
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        position: absolute;
        right: 1em;
        top: 1.8em;
        cursor: pointer;
    }
    .icon_menu_top div{
        width: 100%;
        height: 3px;
        border-radius: 2.5px;
        background: #019EB6;
    }
    .icon_menu_top div:nth-child(2){
        width: 70%;
    }
    .icon_menu_top div:last-child{
        width: 50%;
    }
    .active_menu_icon div{
        width: 100% !important;
    }
    .active_menu_items{
        height: inherit;
        overflow: hidden;
    }
    .menu__paiment{
        width: 300px;
        margin: 0 auto;
    }
}